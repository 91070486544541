import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function Copyright(props) {
	return (
		<Typography variant='body2' color='textSecondary' align='center'>
			{'Copyright © '}
			<a href='https://pennair.com/' target='_blank' rel='noopener noreferrer' className={props.classes.linkFooter}>
				PennAir
			</a>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	toolbar: {
		flexWrap: 'wrap',
	},
	toolbarTitle: {
		flexGrow: 1,
	},
	link: {
		margin: theme.spacing(1, 1.5),
		textDecoration: 'none',
		color: '#fff',
	},
	linkFooter: {
		textDecoration: 'none',
		color: theme.palette.primary.contrastText,
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardHeader: {
		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},
	footer: {
		borderTop: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(8),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(6),
		},
	},
}));

const tiers = [
	{
		title: 'Free',
		subheader: 'Small Business',
		price: '0',
		description: ['5 employees/hosts', 'Email Notifications', 'Email support'],
		buttonText: 'Sign up for free',
		buttonVariant: 'outlined',
	},
	{
		title: 'Business',
		subheader: 'Most popular',
		price: '15',
		description: ['25 employees/hosts', 'Email Notifications', 'Name Tag Printing', 'User Photos', 'Phone & email support'],
		buttonText: 'Get started',
		buttonVariant: 'contained',
	},
	{
		title: 'Enterprise',
		subheader: 'Larger User Base',
		price: '30',
		description: ['Unlimited employees/hosts', 'Email & text Notifications', 'Name Tag Printing', 'User Photos', 'Customize Theme', 'Priority phone & email support'],
		descriptionExtra: ['Unlimited employees/hosts', 'Email & text Notifications', 'Name Tag Printing', 'User Photos', 'Customize Theme', 'Priority phone & email support'],
		buttonText: 'Contact us',
		buttonVariant: 'outlined',
	},
];
const footers = [
	{
		title: 'Company',
		description: ['Team', 'History', 'Contact us', 'Locations'],
	},
	{
		title: 'Features',
		description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
	},
	{
		title: 'Resources',
		description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
	},
	{
		title: 'Legal',
		description: ['Privacy policy', 'Terms of use'],
	},
];

export default function Pricing() {
	const classes = useStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position='static' elevation={0} className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Typography variant='h6' color='inherit' noWrap className={classes.toolbarTitle}>
						Front Desk Assistant
					</Typography>
					<nav>
						<Link variant='button' color='textPrimary' href='#' className={classes.link}>
							Features
						</Link>
						<Link variant='button' color='textPrimary' href='#' className={classes.link}>
							Enterprise
						</Link>
						<Link variant='button' color='textPrimary' href='#' className={classes.link}>
							Support
						</Link>
					</nav>
					<Button variant='contained' component={Link} to='/login'>
						Login
					</Button>
				</Toolbar>
			</AppBar>
			{/* Hero unit */}
			<Container maxWidth='sm' component='main' className={classes.heroContent}>
				<Typography component='h1' variant='h2' align='center' color='textPrimary' gutterBottom>
					Pricing
				</Typography>
				<Typography variant='h5' align='center' color='textSecondary' component='p'>
					Visitor Management System
				</Typography>
			</Container>
			{/* End hero unit */}
			<Container maxWidth='md' component='main'>
				<Grid container spacing={5} alignItems='flex-start'>
					{tiers.map((tier) => (
						// Enterprise card is full width at sm breakpoint
						<Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
							<Card>
								<CardHeader
									title={tier.title}
									subheader={tier.subheader}
									titleTypographyProps={{ align: 'center' }}
									subheaderTypographyProps={{ align: 'center' }}
									action={tier.title === 'Pro' ? <StarIcon /> : null}
									className={classes.cardHeader}
								/>
								<CardContent>
									<div className={classes.cardPricing}>
										<Typography component='h2' variant='h3' color='textPrimary'>
											${tier.price}
										</Typography>
										<Typography variant='h6' color='textSecondary'>
											/mo
										</Typography>
									</div>
									<ul>
										{tier.description.map((line) => (
											<Typography component='li' variant='subtitle1' align='center' key={line}>
												{line}
											</Typography>
										))}
									</ul>
								</CardContent>
								<CardActions>
									<Button fullWidth variant={tier.buttonVariant} color='primary'>
										{tier.buttonText}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
			{/* Footer */}
			<Container maxWidth='md' component='footer' className={classes.footer}>
				<Grid container spacing={4} justify='space-evenly'>
					{footers.map((footer) => (
						<Grid item xs={6} sm={3} key={footer.title}>
							<Typography variant='h6' color='textPrimary' gutterBottom>
								{footer.title}
							</Typography>
							<ul>
								{footer.description.map((item) => (
									<li key={item}>
										<Link href='#' variant='subtitle1' className={classes.linkFooter}>
											{item}
										</Link>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
				<Box mt={5}>
					<Copyright classes={classes} />
				</Box>
			</Container>
			{/* End footer */}
		</React.Fragment>
	);
}
