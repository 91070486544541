import React, { Component } from 'react';
import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import BackspaceIcon from '@material-ui/icons/Backspace';
import PrintIcon from '@material-ui/icons/Print';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../Alert';

class Management extends Component {
	constructor(props) {
		super(props);
		this.state = {
			signedinPeople: [],
			alert: null,
		};
		props.setTitle('Dashboard');
	}

	componentDidMount() {
		this.getSignedinPeople();
	}

	getSignedinPeople = async () => {
		try {
			// get signins
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/get?query={"signedin":true,"siteid":"${this.props.user.uid}"}`)
				.then((response) => {
					this.setState({ signedinPeople: response.data.result });
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	handleSignOut = async (id) => {
		try {
			// set signin processed
			await axios
				.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/update?query={"_id":"${id}","siteid":"${this.props.user.uid}"}`, {
					body: {
						signedin: false,
					},
				})
				.then((response) => {
					this.getSignedinPeople();
					console.log('Document successfully updated!');
					this.handleSnackbarOpen('Sign out!', 'success');
				})
				.catch((error) => {
					console.error('Error updating document: ', error);
					this.handleSnackbarOpen('Error! Sign out failed.', 'error');
				});
		} catch (error) {
			console.error('Error updating document: ', error);
			this.handleSnackbarOpen('Error! Sign out failed.', 'error');
		}
	};

	handlerReprint = async (id) => {
		//
		// reprint
		var timeoutCheck = setTimeout(() => {
			this.setState(
				{
					snackbarOpen: false,
					snackbarMessage: '',
				},
				() => {
					this.handleSnackbarOpen('Error sending print job!', 'success');
				}
			);
		}, 5000);

		this.props.socket.emit('join', 'reprintQueued:' + this.props.user.uid);
		this.props.socket.emit('req:relayReprint', {
			site: this.props.user.uid,
			id: id,
		});

		this.props.socket.once('res:reprintQueued', (returnId) => {
			if (returnId === id) {
				this.handleSnackbarOpen('Reprint job queued.', 'info');
				clearTimeout(timeoutCheck);
			}

			this.props.socket.emit('leave', 'reprintQueued:' + id);
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbarOpen: false,
			snackbarMessage: '',
		});
	};
	handleSnackbarOpen = (message, severity) => {
		this.setState({
			snackbarOpen: true,
			snackbarMessage: message,
			snackbarSeverity: severity,
		});
	};

	render() {
		return (
			<>
				<Grid container spacing={3}>
					{this.state.signedinPeople.map((person, i) => (
						<Grid item xs={12}>
							<Paper elevation={3}>
								<List>
									<ListItem alignItems='flex-start'>
										<ListItemAvatar>
											<Avatar
												style={{
													marginRight: '30px',
													width: '100px',
													height: '100px',
												}}
												alt={person.name}
												src={person.picture}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={
												<React.Fragment>
													<Typography component='span' variant='body1' color='textPrimary'>
														{person.name}
													</Typography>
													{' — '}
													{person.nonbusiness ? 'Nonbusiness' : person.company}
													<img
														alt='sign'
														height={50}
														style={{
															marginBottom: '-32px',
															marginLeft: '12px',
														}}
														src={person.signature}
													/>
												</React.Fragment>
											}
											secondary={
												<React.Fragment>
													<br />
													<Typography component='span' variant='body2' color='textPrimary'>
														Visiting:
														<br />
													</Typography>
													{person.associates.map((associate, i) => (
														<Chip
															style={{ marginRight: '8px' }}
															avatar={<Avatar src={associate.photo} alt={associate.displayName.charAt(0).toUpperCase()} />}
															label={associate.displayName}
															color='primary'
															variant='outlined'
														/>
													))}
												</React.Fragment>
											}
										/>
									</ListItem>
								</List>
								<Fab
									variant='extended'
									color='primary'
									aria-label='add'
									style={{
										marginBottom: '12px',
										marginLeft: '12px',
									}}
									onClick={() => this.handleSignOut(person._id)}
								>
									<BackspaceIcon style={{ marginRight: '8px' }} />
									Sign out
								</Fab>
								<Fab
									variant='extended'
									aria-label='add'
									style={{
										marginBottom: '12px',
										marginLeft: '12px',
									}}
									onClick={() => this.handlerReprint(person._id)}
								>
									<PrintIcon style={{ marginRight: '8px' }} />
									Reprint
								</Fab>
							</Paper>
						</Grid>
					))}
					{this.state.signedinPeople.length === 0 && (
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<Typography variant='h5' gutterBottom style={{ marginTop: 100 }}>
								No one is signed in.
							</Typography>
						</Grid>
					)}
					{this.state.alert}
				</Grid>

				<Snackbar open={this.state.snackbarOpen} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
					<Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
						{this.state.snackbarMessage}
					</Alert>
				</Snackbar>
			</>
		);
	}
}

export default Management;
