import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme, rgbToHex } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import YoutubeBackground from 'react-youtube-background';

const styles = (theme) => ({
	root: {
		position: 'relative',
		overflow: 'hidden',
	},
	appFrame: {
		position: 'relative',
		height: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	statusBar: {
		width: '100%',
		height: 24,
	},
	menuButton: {
		marginRight: 20,
	},
	code: {
		margin: theme.spacing(2),
		fontSize: 16,
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
});

function ColorDemo(props) {
	const { classes, data } = props;
	const theme = useTheme();
	const primary = theme.palette.augmentColor({
		main: data.primary,
		output:
			data.primaryShade === 4
				? `${data.primaryHue}`
				: `{
      main: '${data.primary}',
    }`,
	});

	return (
		<div className={classes.root}>
			<Typography component='label' gutterBottom variant='h6'>
				Theme Preview:
			</Typography>
			<div className={classes.appFrame}>
				<div className={classes.statusBar} style={{ backgroundColor: primary.dark }} />
				<AppBar position='static' style={{ backgroundColor: primary.main }}>
					<Toolbar style={{ color: primary.contrastText }}>
						<Typography component='div' variant='h6' color='inherit'>
							Welcome to {data.companyName}
						</Typography>
						<img
							alt='logo'
							style={{ position: 'absolute', right: '20px', height: '48px' }}
							src={!data.logo ? '' : data.logo}
							onError={(e) => {
								e.target.src =
									'https://logo.pennair.tech/logo?tag=Front%20Desk%20Assistant&penn=' +
									rgbToHex(primary.contrastText).substring(1, 4) +
									'&air=' +
									rgbToHex(primary.contrastText).substring(1, 4) +
									'&line=' +
									rgbToHex(primary.contrastText).substring(1, 4) +
									'&text=' +
									rgbToHex(primary.contrastText).substring(1, 4) +
									'&dot=' +
									rgbToHex(primary.contrastText).substring(1, 4);
							}}
						/>
					</Toolbar>
				</AppBar>

				{data.backgroundType === 'video' ? (
					<YoutubeBackground style={{ zIndex: 0, width: '100%', height: 400 }} videoId={data.backgroundValue} overlay={'rgba(0,0,0,.6)'}>
						<Fab className={classes.fab} variant='extended' style={{ zIndex: 1, backgroundColor: primary.main, color: primary.contrastText }} size='large' aria-label='add'>
							Visitor Sign In
							<ExitToAppIcon style={{ marginLeft: '8px' }} />
						</Fab>
					</YoutubeBackground>
				) : (
					<div style={{ width: '100%', height: 400, background: '#808080' }}>
						<img
							alt='BackgroundImage'
							src={data.backgroundValue}
							onError={(e) => {
								e.target.src = '/images/' + Math.floor(Math.random() * 10) + '.jpg';
							}}
							style={{ objectFit: 'cover', width: '100%', height: '100%' }}
						/>

						<Fab className={classes.fab} variant='extended' style={{ zIndex: 1, backgroundColor: primary.main, color: primary.contrastText }} size='large' aria-label='add'>
							Visitor Sign In
							<ExitToAppIcon style={{ marginLeft: '8px' }} />
						</Fab>
					</div>
				)}
			</div>
		</div>
	);
}

ColorDemo.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default withStyles(styles)(ColorDemo);
