import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

var firebaseConfig = {
	apiKey: 'AIzaSyCLJyMYrAJbRKUc-fXfRLlpPxyRi3c_jpo',
	authDomain: 'test-18ee8.firebaseapp.com',
	databaseURL: 'https://test-18ee8.firebaseio.com',
	projectId: 'test-18ee8',
	storageBucket: 'test-18ee8.appspot.com',
	messagingSenderId: '40953446389',
	appId: '1:40953446389:web:709de7f904330a6a142abc',
	measurementId: 'G-J6T9HNH1K2',
};

firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();
// export const storage = firebase.storage();
export const firebaseAuth = firebase.auth;
