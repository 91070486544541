import React, { Component } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { rgbToHex } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import Stepper from './Stepper';
import Agreement from './Agreement';
import TabPanel from '../TabPanel';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import SignatureCanvas from 'react-signature-canvas';
import Webcam from 'react-webcam';

import SweetAlert from 'react-bootstrap-sweetalert';
import welcomeSfx from '../../sounds/welcome.mp3';
import imperialAlertSfx from '../../sounds/imperialAlert.mp3';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 0,
			steps: ['Information', 'More Information', 'Read & Agree', 'Your Visit', 'Your Picture', 'Confirm'],
			isComplete: false,
			isLoading: false,
			fullname: '',
			company: '',
			nonbusiness: false,
			email: '',
			signature: '',
			terms: false,
			vistingFilter: '',
			associates: [],
			picture: '',
			sendMeCopy: false,
			webcamReady: false
		};
		this.StepperChild = React.createRef();
		this.sigPad = React.createRef();
		this.Webcam = React.createRef();
		this.handleSigRender = this.handleSigRender.bind(this);
		this.handleClearSig = this.handleClearSig.bind(this);
		this.handleSigDraw = this.handleSigDraw.bind(this);
		this.handleCapturePicture = this.handleCapturePicture.bind(this);
	}

	componentDidMount() {
		this.getAssociates();
	}

	async getAssociates() {
		// db.collection('locations')
		// 	.doc(this.props.match.params.site)
		// 	.get()
		// 	.then((doc) => {
		// 		var data = doc.data().associates.sort((a, b) => (a.name > b.name ? 1 : -1));
		// 		this.setState({ associates: data });
		// 	});

		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/associates/get?query={"uid":"${window.location.pathname.split('/')[2]}"}`)
				.then((response) => {
					console.log(response?.data?.result[0]?.associates);

					var associates = response?.data?.result[0]?.associates.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
					this.setState({ associates: associates });
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	}

	handleNext = () => {
		this.setState({ step: this.state.step + 1 }, () => {
			this.checkIsComplete();
			window.scrollTo(0, 0);
		});
		this.StepperChild.current.setStep(this.state.step + 1);
		window.scrollTo(0, 0);
	};

	handleBack = () => {
		this.setState({ step: this.state.step - 1 }, () => {
			this.resetWebcam() //a hack for now
			this.checkIsComplete();
			window.scrollTo(0, 0);
		});
		this.StepperChild.current.setStep(this.state.step - 1);
	};

	checkIsComplete = (e) => {
		if (this.state.step === 2) {
			this.handleSigRender();
		}
		if (this.state.step === 0 && this.state.fullname.length >= 2) {
			if (this.state.company.length >= 2 || this.state.nonbusiness) {
				this.setState({ isComplete: true });
			} else {
				this.setState({ isComplete: false });
			}
		} else if (this.state.step === 1) {//&& this.state.fever === 'No' && this.state.symptoms === 'No') {
			this.setState({ isComplete: true });
		} else if (this.state.step === 2 && this.state.terms && this.state.signature !== '') {
			this.setState({ isComplete: true });
		} else if (this.state.step === 3 && this.state.associates.filter((a) => a.visiting === true).length > 0) {
			this.setState({ isComplete: true });
		} else if (this.state.step === 4 && this.state.picture !== '') {
			this.setState({ isComplete: true });
		} else {
			this.setState({ isComplete: false });
		}
	};

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			console.log('do validate');

			if (this.state.step === 0 && this.state.fullname.length >= 2) {
				if (this.state.company.length >= 2 || this.state.nonbusiness) {
					this.handleNext();
				} else {
					this.setState({ isComplete: false });
				}
			} else if (this.state.step === 1 && this.state.fever === 'No' && this.state.symptoms === 'No') {
				this.handleNext();
			}
		}
	};

	handleNonbusiness = () => {
		this.setState({ nonbusiness: !this.state.nonbusiness }, () => {
			if (this.state.nonbusiness) {
				this.setState({ company: '' });
			}
			this.checkIsComplete();
		});
	};

	handleSigRender() {
		this.sigPad.fromDataURL(this.state.signature);
	}
	handleSigDraw() {
		this.setState({ signature: this.sigPad.toDataURL() }, () => {
			this.checkIsComplete();
		});
	}
	handleClearSig() {
		this.sigPad.clear();
		this.setState({ signature: '' }, () => {
			this.checkIsComplete();
		});
	}

	handleterms = () => {
		this.setState({ terms: !this.state.terms }, () => {
			this.checkIsComplete();
		});
	};

	handleSelectVisiting = (event) => {
		var visitngTemp = this.state.associates;
		if (visitngTemp[parseInt(event.target.value)].visiting) {
			visitngTemp[parseInt(event.target.value)].visiting = false;
			// }
		} else {
			// visitngTemp.push(event.target.value);
			visitngTemp[parseInt(event.target.value)].visiting = true;
		}
		this.setState({ associates: visitngTemp }, () => {
			this.checkIsComplete();
		});
	};

	handleCapturePicture() {
		this.setState({ picture: this.Webcam.current.getScreenshot() }, () => {
			this.checkIsComplete();
		});
	}

	handleClearPicture = () => {
		this.setState({ picture: '' }, () => {
			this.checkIsComplete();
		});
	};

	handleWebcamTimeout = () => {
		this.setState({ webcamReady: true });
	}

	resetWebcam = () => {
		this.setState({ webcamReady: false });
	}

	handleSendCopy = () => {
		this.setState({ sendMeCopy: !this.state.sendMeCopy });
	};

	handleSignIn = () => {
		this.setState({ isLoading: true }, async () => {
			try {
				console.log();
				await axios
					.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/create`, {
						body: {
							siteid: this.props.match.params.site,
							name: this.state.fullname,
							company: this.state.company,
							nonbusiness: this.state.nonbusiness,
							email: this.state.email,
							signature: this.state.signature,
							terms: this.state.terms,
							associates: this.state.associates.filter((a) => a.visiting === true),
							picture: this.state.picture,
							signedin: true,
							sendMeCopy: this.state.sendMeCopy,
							timestamp: Date.now(),
						},
					})
					.then((response) => {
						// console.log(response.data);
						console.log('Document successfully written!');
						this.setState({ isLoading: false }, () => {
							this.props.socket.emit('req:relayNewSignin', this.props.match.params.site);

							this.successAlert();
						});
					})
					.catch((error) => {
						console.error('Error writing document: ', error);
						this.setState({ isLoading: false }, () => {
							this.warningAlert();
						});
					});
			} catch (error) {
				console.error(error);
			}
		});
	};

	successAlert = () => {
		const welcomeSfxAudio = new Audio(welcomeSfx);
		welcomeSfxAudio.play();
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ backgroundColor: this.props.data.type === 'dark' ? '#424242' : '#fff' }}
					title='Welcome to PennAir'
					onCancel={() => this.props.history.push('/client/' + this.props.match.params.site)}
					customButtons={
						<React.Fragment>
							<Link to={'/client/' + this.props.match.params.site} style={{ textDecoration: 'none' }}>
								<Button variant='contained' size='large' color='primary'>
									continue
								</Button>
							</Link>
						</React.Fragment>
					}
				>
					Your name tag will print shortly.
				</SweetAlert>
			),
		});
		this.timeoutReturnHome();
	};
	warningAlert = () => {
		const imperialAlertSfxAudio = new Audio(imperialAlertSfx);
		imperialAlertSfxAudio.play();
		this.setState({
			alert: (
				<SweetAlert
					danger
					style={{ backgroundColor: this.props.data.type === 'dark' ? '#424242' : '#fff' }}
					title='An error occured.'
					onCancel={() => this.hideAlert()}
					customButtons={
						<React.Fragment>
							<Button
								variant='contained'
								size='large'
								color='secondary'
								onClick={() => {
									this.hideAlert();
									this.handleSignIn();
								}}
								style={{ margin: '8px' }}
							>
								Retry
							</Button>
							<Button
								variant='contained'
								size='large'
								onClick={() => {
									this.props.history.push('/client/' + this.props.match.params.site);
								}}
							>
								Cancel
							</Button>
						</React.Fragment>
					}
				>
					Please try again, or ask for assistance.
				</SweetAlert>
			),
		});
	};
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	timeoutReturnHome() {
		setTimeout(() => {
			this.props.history.push('/client/' + this.props.match.params.site);
		}, 5000);
	}

	validateEmail = (email) => {
		var re = /\S+@\S+\.\S+/;
		console.log("EMAIL IS: " + re.test(email));
		return re.test(email);
	}

	render() {
		return (
			<div>
				<AppBar position='static'>
					<Toolbar>
						<Link to={'/client/' + this.props.match.params.site}>
							<IconButton edge='start' color='inherit' aria-label='menu' style={{ color: 'white' }}>
								<ArrowBackIcon />
							</IconButton>
						</Link>
						<Typography align='center' variant='h6' color='inherit'>
							{this.state.steps[this.state.step]}
						</Typography>
						<img
							alt='logo'
							style={{ position: 'absolute', right: '20px', height: '48px' }}
							src={!this.props.data.logo ? '' : this.props.data.logo}
							onError={(e) => {
								e.target.src =
									'https://logo.pennair.tech/logo?tag=Front%20Desk%20Assistant&penn=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&air=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&line=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&text=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&dot=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4);
							}}
						/>
					</Toolbar>
				</AppBar>

				<Container style={{ paddingLeft: '70px', paddingRight: '70px', paddingTop: '30px', marginBottom: '130px' }}>
					<TabPanel value={this.state.step} index={0}>
						<TextField
							type='text'
							value={this.state.fullname}
							onChange={(e) => {
								this.setState({ fullname: e.target.value });
							}}
							onKeyUp={(e) => {
								this.checkIsComplete();
							}}
							onKeyDown={this._handleKeyDown}
							label='Full Name'
							variant='outlined'
							style={{ margin: 8 }}
							fullWidth
							required
							error={this.state.fullname.length < 2}
						/>
						<TextField
							type='text'
							value={this.state.company}
							onChange={(e) => {
								this.setState({ company: e.target.value }, () => {
									this.checkIsComplete();
								});
							}}
							onKeyUp={(e) => {
								this.checkIsComplete();
							}}
							onKeyDown={this._handleKeyDown}
							label='Company Name'
							variant='outlined'
							style={{ margin: 8 }}
							fullWidth
							required={!this.state.nonbusiness}
							disabled={this.state.nonbusiness}
							error={this.state.company.length < 2 && !this.state.nonbusiness}
						/>
						<FormControlLabel
							style={{ marginLeft: 12 }}
							control={<Checkbox checked={this.state.nonbusiness} onChange={this.handleNonbusiness} name='nonbusiness' color='primary' />}
							label='Nonbusiness'
						/>
					</TabPanel>
					<TabPanel value={this.state.step} index={1}>
						<TextField
							type='email'
							value={this.state.email}
							onChange={(e) => {
								this.setState({ email: e.target.value }, () => {
									this.checkIsComplete();
								});
							}}
							onKeyUp={(e) => {
								this.checkIsComplete();
							}}
							onKeyDown={this._handleKeyDown}
							label='Email Address(Optional)'
							variant='outlined'
							style={{ margin: 8 }}
							//error={this.validateEmail(this.state.email) !== true}
							fullWidth
						/>
						<div>{/*
						<FormControl component='fieldset' style={{ margin: 8 }} fullWidth>
							<FormLabel component='legend'>I had a fever (Above 200°) within the last 3 days.</FormLabel>
							<RadioGroup
								value={this.state.fever}
								row
								aria-label='fever'
								name='fever'
								defaultValue='top'
								onChange={(e) => {
									this.setState({ fever: e.target.value }, () => {
										this.checkIsComplete();
									});
								}}
							>
								<FormControlLabel value='Yes' control={<Radio color='primary' />} label='Yes' />
								<FormControlLabel value='No' control={<Radio color='primary' />} label='No' />
							</RadioGroup>
						</FormControl>
						<FormControl component='fieldset' style={{ margin: 8 }} fullWidth>
							<FormLabel component='legend'>I had a combination of two or more of: Shortness of breath, Cough, Headache, and/or diarrhea.</FormLabel>
							<RadioGroup
								value={this.state.symptoms}
								row
								aria-label='symptoms'
								name='symptoms'
								defaultValue='top'
								onChange={(e) => {
									this.setState({ symptoms: e.target.value }, () => {
										this.checkIsComplete();
									});
								}}
							>
								<FormControlLabel value='Yes' control={<Radio color='primary' />} label='Yes' />
								<FormControlLabel value='No' control={<Radio color='primary' />} label='No' />
							</RadioGroup>
						</FormControl>
						*/}</div>
					</TabPanel>
					<TabPanel value={this.state.step} index={2}>
						<Agreement />
						<FormLabel style={{ fontSize: '.7rem' }} component='legend'>
							Signature:
						</FormLabel>
						<Paper elevation={3}>
							<SignatureCanvas
								penColor='#00a9e2'
								canvasProps={{ height: 400, className: 'sigPad' }}
								onEnd={this.handleSigDraw}
								ref={(ref) => {
									this.sigPad = ref;
								}}
							/>
						</Paper>
						<Button style={{ margin: '4px' }} size='small' variant='contained' onClick={this.handleClearSig}>
							Clear
						</Button>
						<FormControlLabel
							style={{ float: 'right' }}
							control={<Checkbox checked={this.state.terms} onChange={this.handleterms} name='terms' color='primary' />}
							label='I accept the Terms and Conditions'
						/>
					</TabPanel>
					<TabPanel value={this.state.step} index={3}>
						<TextField
							type='text'
							value={this.state.vistingFilter}
							onChange={(e) => {
								this.setState({ vistingFilter: e.target.value });
							}}
							onKeyDown={this._handleKeyDown}
							label='Search'
							variant='outlined'
							style={{ marginTop: -30, marginBottom: 16 }}
							fullWidth
						/>
						<Grid container spacing={3}>
							{this.state.associates.map((associate, i) => (
								<Grid
									key={associate.email}
									item
									lg={4}
									md={6}
									sm={6}
									xs={12}
									style={{ display: this.state.associates[i].displayName.toLowerCase().includes(this.state.vistingFilter.toLowerCase()) ? 'block' : 'none' }}
								>
									<Card>
										<FormControlLabel
											style={{ padding: '8px 8px 8px 8px', width: '100%' }}
											control={<Checkbox checked={this.state.associates[i].visiting} onChange={this.handleSelectVisiting} value={i} color='primary' />}
											label={
												<div style={{ height: '49px' }}>
													<ListItem style={{ padding: 0, position: 'relative', top: -4, height: 58 }}>
														<ListItemAvatar>
															<Avatar alt={associate.displayName} src={associate.photo ? associate.photo : '/no-image'} />
														</ListItemAvatar>
														<ListItemText primary={associate.displayName} secondary={associate.title} />
													</ListItem>
												</div>
											}
										/>
									</Card>
								</Grid>
							))}
						</Grid>
					</TabPanel>
					<TabPanel
						value={this.state.step}
						index={4}
						style={{
							padding: 0,
						}}
					>
						<div
							style={{
								margin: 'auto',
								width: '350px',
								height: '350px',
								position: 'absolute',
								left: 0,
								right: 0,
							}}
						>

							<Avatar
								style={{
									padding: 0,
									width: '350px',
									height: '350px',
								}}
							>
								{!this.state.webcamReady && <div style={{ 'text-align': 'center' }}>
									<h1 style={{ fontSize: 30, margin: 2 }}>{"Loading Camera..."}</h1>
									<br></br>
									<h1 style={{ fontSize: 10, margin: 2 }}>{"*This may take up to 10 seconds"}</h1>
								</div>}
								<Webcam
									style={{ display: (this.state.picture === '' && this.state.webcamReady == true) ? 'block' : 'none' }}
									ref={this.Webcam}
									audio={false}
									mirrored={true}
									width={620}
									height={350}
									screenshotFormat='image/jpeg'
									videoConstraints={{ width: 620, height: 350, facingMode: "user" }}
									onUserMedia={this.handleWebcamTimeout}
								/>
								<img alt='person_image' style={{ display: this.state.picture === '' ? 'none' : 'block' }} src={this.state.picture} />
							</Avatar>

							{(this.state.picture === '') ? (
								this.state.webcamReady &&
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										padding: '20px',
									}}
								>
									<IconButton
										onClick={this.handleCapturePicture}
										style={{
											color: 'black',
											zIndex: 9999,
										}}
									>
										<CameraAltIcon fontSize='large' />
									</IconButton>
									<div id='circleWave' style={{ position: 'absolute', transform: 'translate(-50%, -50%)', zIndex: -2 }}></div>
								</div>
							) : (
								this.state.webcamReady &&
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										padding: '20px',
									}}
								>
									<IconButton
										onClick={this.handleClearPicture}
										style={{
											color: 'black',
											zIndex: 9999,
										}}
									>
										<ClearIcon fontSize='large' />
									</IconButton>
								</div>
							)}
						</div>
					</TabPanel>
					<TabPanel value={this.state.step} index={5}>
						<Backdrop open={this.state.isLoading} style={{ zIndex: 9999 }}>
							<CircularProgress color='inherit' />
						</Backdrop>
						<Paper elevation={3}>
							<List>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											style={{
												marginRight: '30px',
												width: '60px',
												height: '60px',
											}}
											alt={this.state.fullname}
											src={this.state.picture}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={
											<React.Fragment>
												<Typography component='span' variant='body1' color='textPrimary'>
													{this.state.fullname}
												</Typography>
												{' — '}
												{this.state.nonbusiness ? 'Nonbusiness' : this.state.company}
											</React.Fragment>
										}
										secondary={
											<React.Fragment>
												<Typography component='span' variant='body2' color='textPrimary'>
													Visiting:
													<br />
												</Typography>
												{this.state.associates
													.filter((a) => a.visiting === true)
													.map((associate, i) => (
														<Chip
															style={{ marginRight: '8px' }}
															avatar={<Avatar alt={associate.displayName} src={associate.photo ? associate.photo : '/no-image'} />}
															label={associate.displayName}
															color='primary'
															variant='outlined'
														/>
													))}
											</React.Fragment>
										}
									/>
								</ListItem>
							</List>
						</Paper>
						{this.state.email !== '' ? (
							<FormControlLabel
								style={{ float: 'right' }}
								control={<Checkbox checked={this.state.sendMeCopy} name='sendMeCopy' color='primary' onClick={this.handleSendCopy} />}
								label='Send me a copy'
							/>
						) : (
							''
						)}

						<div
							style={{
								margin: 'auto',
								width: '125px',
								paddingTop: '60px',
							}}
						>
							<Fab variant='extended' color='primary' aria-label='add' onClick={this.handleSignIn}>
								<DoneIcon />
								Confirm
							</Fab>

							<div id='circleWave' style={{ position: 'relative', top: -54, width: 124.13, height: 60, borderRadius: 50, transform: 'translate(-50%, -50%)', zIndex: -2 }}></div>
						</div>
						{this.state.alert}
					</TabPanel>
				</Container>

				<IconButton
					color='primary'
					onClick={this.handleBack}
					style={{ display: this.state.step <= 0 ? 'none' : 'block', position: 'fixed', top: '50%', left: '40px', transform: 'translate(-50%, -50%)' }}
				>
					<ArrowBackIcon fontSize='large' />
				</IconButton>

				<div id='circleWave' style={{ display: this.state.isComplete <= 0 ? 'none' : 'block', position: 'fixed', top: '46%', right: '14px', transform: 'translate(-50%, -50%)' }}></div>
				<IconButton
					color='primary'
					disabled={!this.state.isComplete}
					onClick={this.handleNext}
					style={{ display: this.state.step >= 5 ? 'none' : 'block', position: 'fixed', top: '50%', right: '-20px', transform: 'translate(-50%, -50%)' }}
				>
					<ArrowForwardIcon fontSize='large' />
				</IconButton>

				<Stepper step={this.state.step} steps={this.state.steps} ref={this.StepperChild} />
			</div >
		);
	}
}

export default Signin;
