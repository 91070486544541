import React from 'react';

import axios from 'axios';

import PropTypes from 'prop-types';
import { rgbToHex, withStyles, useTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Slider from '@material-ui/core/Slider';
import { capitalize } from '@material-ui/core/utils';
import ColorDemo from './ColorDemo';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Resizer from 'react-image-file-resizer';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

const hues = Object.keys(colors).slice(1, 17);
const shades = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];

const styles = (theme) => ({
	radio: {
		padding: 0,
	},
	radioIcon: {
		width: 64,
		height: 64,
	},
	radioIconSelected: {
		width: 64,
		height: 64,
		border: '1px solid white',
		color: theme.palette.common.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	swatch: {
		width: 256,
	},
	sliderContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	slider: {
		width: 'calc(100% - 80px)',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	colorBar: {
		marginTop: theme.spacing(2),
	},
	colorSquare: {
		width: 85.3,
		height: 85.3,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		marginLeft: theme.spacing(1),
	},
});

function ColorTool(props) {
	const { classes } = props;
	// const dispatch = React.useContext(DispatchContext);
	const theme = useTheme();
	const [state, setState] = React.useState({
		primary: props.savedTheme.main,
		primaryInput: props.savedTheme.main,
		primaryHue: 'blue',
		primaryShade: 4,
		type: props.savedTheme.type ?? 'light',
		backgroundType: props.savedTheme.backgroundType,
		backgroundValue: props.savedTheme.backgroundValue,
		companyName: props.savedTheme.companyName ?? 'Front Desk Assistant',
		logo: props.savedTheme.logo,

		saveloading: false,
	});

	const handleChangeColor = (name) => (event) => {
		const isRgb = (string) => /rgb\([0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\)/i.test(string);

		const isHex = (string) => /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(string);

		let {
			target: { value: color },
		} = event;

		setState((prevState) => ({
			...prevState,
			[`${name}Input`]: color,
		}));

		let isValidColor = false;

		if (isRgb(color)) {
			isValidColor = true;
		} else if (isHex(color)) {
			isValidColor = true;
			if (color.indexOf('#') === -1) {
				color = `#${color}`;
			}
		}

		if (isValidColor) {
			setState((prevState) => ({
				...prevState,
				[name]: color,
			}));
		}
	};

	const handleChangeHue = (name) => (event) => {
		const hue = event.target.value;
		const color = colors[hue][shades[state[`${name}Shade`]]];

		setState({
			...state,
			[`${name}Hue`]: hue,
			[name]: color,
			[`${name}Input`]: color,
		});
	};

	const handleChangeShade = (name) => (event, shade) => {
		const color = colors[state[`${name}Hue`]][shades[shade]];
		setState({
			...state,
			[`${name}Shade`]: shade,
			[name]: color,
		});
	};
	const handleChangeBackgroundType = (event) => {
		setState({
			...state,
			backgroundType: event.target.value,
			backgroundValue: '',
		});
	};
	const handleChangeImageUpload = (event) => {
		let file = event.target.files[0];
		Resizer.imageFileResizer(
			file,
			2048,
			2048,
			'PNG',
			70,
			0,
			(uri) => {
				setState({
					...state,
					backgroundValue: uri,
				});
			},
			'base64'
		);
	};
	const handleChangeYouTubeId = (event) => {
		setState({
			...state,
			backgroundValue: event.target.value,
		});
	};
	const handleChangeLogoUpload = (event) => {
		let file = event.target.files[0];
		Resizer.imageFileResizer(
			file,
			512,
			512,
			'PNG',
			70,
			0,
			(uri) => {
				setState({
					...state,
					logo: uri,
				});
			},
			'base64'
		);
	};
	const handleChangeCompanyName = (event) => {
		setState({
			...state,
			companyName: event.target.value,
		});
	};
	const handleChangeType = (event) => {
		setState({
			...state,
			type: event.target.checked ? 'dark' : 'light',
		});
	};

	const handleChangeDocsColors = async () => {
		setState({ ...state, saveloading: true });

		const primary = theme.palette.augmentColor({ main: state.primary });

		try {
			await axios
				.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/update?query={"uid":"${props.user.uid}"}`, {
					body: {
						theme: {
							main: rgbToHex(primary.main),
							light: rgbToHex(primary.light),
							dark: rgbToHex(primary.dark),
							contrastText: rgbToHex(primary.contrastText).substring(0, 4),
							type: state.type,
							backgroundType: state.backgroundType,
							backgroundValue: state.backgroundValue,
							companyName: state.companyName,
							logo: state.logo,
						},
					},
				})
				.then((response) => {
					// console.log(response.data);
					console.log('Document successfully written!');
					setState({ ...state, saveloading: false });

					props.socket.emit('req:relayThemeChange', props.user.uid);
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
					setState({ ...state, saveloading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	const handleResetDocsColors = async () => {
		try {
			await axios
				.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/update?query={"uid":"${props.user.uid}"}`, {
					body: {
						theme: {
							light: '#33bae7',
							main: '#00a9e2',
							dark: '#00769e',
							contrastText: '#000',
							type: 'light',
							backgroundType: 'image',
							backgroundValue: '',
							companyName: 'Front Desk Assistant',
							logo: '',
						},
					},
				})
				.then((response) => {
					// console.log(response.data);
					console.log('Document successfully written!');
					setState({ ...state, saveloading: false });

					props.socket.emit('req:relayThemeChange', props.user.uid);
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
					setState({ ...state, saveloading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	const colorBar = (color) => {
		const background = theme.palette.augmentColor({ main: color });

		return (
			<Grid container className={classes.colorBar}>
				{['dark', 'main', 'light'].map((key) => (
					<div className={classes.colorSquare} style={{ backgroundColor: background[key] }} key={key}>
						<Typography variant='caption' style={{ color: theme.palette.getContrastText(background[key]) }}>
							{rgbToHex(background[key])}
						</Typography>
					</div>
				))}
			</Grid>
		);
	};

	const colorPicker = (intent) => {
		const intentInput = state[`${intent}Input`];
		const intentShade = state[`${intent}Shade`];
		const color = state[`${intent}`];

		var sliderEnabled = false;

		hues.forEach((hue) => {
			const shade = shades[state.primaryShade];
			const backgroundColor = colors[hue][shade];
			if (state[intent] === backgroundColor) {
				sliderEnabled = true;
			}
		});

		return (
			<Grid item xs={12}>
				<Typography component='label' gutterBottom htmlFor={intent} variant='h6'>
					{capitalize(intent)} Color:
				</Typography>

				<TextField variant='outlined' id={intent} value={intentInput} onChange={handleChangeColor(intent)} fullWidth />
				<div className={classes.sliderContainer}>
					<Typography id={`${intent}ShadeSliderLabel`}>Shade:</Typography>
					<Slider
						className={classes.slider}
						value={intentShade}
						min={0}
						max={13}
						step={1}
						onChange={handleChangeShade(intent)}
						aria-labelledby={`${intent}ShadeSliderLabel`}
						disabled={!sliderEnabled}
					/>
					<Typography>{shades[intentShade]}</Typography>
				</div>
				<FormControlLabel control={<Switch checked={state.type === 'dark'} onChange={handleChangeType} name='checkedB' color='primary' />} label='Dark Mode' style={{ margin: 8 }} />
				<div className={classes.swatch}>
					{hues.map((hue) => {
						const shade = shades[state.primaryShade];
						const backgroundColor = colors[hue][shade];

						return (
							<Tooltip placement='right' title={hue} key={hue}>
								<Radio
									className={classes.radio}
									color='default'
									checked={state[intent] === backgroundColor}
									onChange={handleChangeHue(intent)}
									value={hue}
									name={intent}
									aria-labelledby={`tooltip-${intent}-${hue}`}
									icon={<div className={classes.radioIcon} style={{ backgroundColor }} />}
									checkedIcon={
										<div className={classes.radioIconSelected} style={{ backgroundColor }}>
											<CheckIcon style={{ fontSize: 30 }} />
										</div>
									}
								/>
							</Tooltip>
						);
					})}
				</div>
				{colorBar(color)}
			</Grid>
		);
	};

	return (
		<Grid container spacing={5} className={classes.root}>
			<Grid item xs={12} sm={6} md={6}>
				<Grid container spacing={5} className={classes.root}>
					<Grid item xs={12}>
						<Typography component='label' gutterBottom variant='h6'>
							Home Page Background:
						</Typography>
						<FormControl component='fieldset' fullWidth>
							<RadioGroup row aria-label='position' name='position' defaultValue='top' value={state.backgroundType} onChange={handleChangeBackgroundType}>
								<FormControlLabel value='video' control={<Radio color='primary' />} label='YouTube Video' />
								<FormControlLabel value='image' control={<Radio color='primary' />} label='Still Image' />
							</RadioGroup>
						</FormControl>
						{state.backgroundType === 'video' ? (
							<TextField
								label='YouTube Video ID'
								variant='outlined'
								fullWidth
								value={state.backgroundValue.startsWith('data:') ? '' : state.backgroundValue}
								onChange={handleChangeYouTubeId}
							/>
						) : (
							<Button variant='contained' color='primary' size='large' component='label'>
								Upload Image
								<input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleChangeImageUpload} />
							</Button>
						)}
					</Grid>
					<Grid item xs={12}>
						<Typography component='label' gutterBottom variant='h6'>
							Company:
						</Typography>
						<TextField label='Company Name' variant='outlined' fullWidth value={state.companyName} onChange={handleChangeCompanyName} style={{ marginTop: 12 }} />
						<Button variant='contained' color='primary' size='large' component='label' style={{ marginTop: 12 }}>
							Upload Logo
							<input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleChangeLogoUpload} />
						</Button>
					</Grid>
					<Grid item xs={12}>
						{colorPicker('primary')}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6} md={6}>
				<ColorDemo data={state} />
			</Grid>
			<Grid item xs={12}>
				<Button
					variant='contained'
					color='primary'
					disabled={state.saveloading}
					onClick={handleChangeDocsColors}
					endIcon={state.saveloading ? <CircularProgress style={{ color: 'white', height: 20, width: 20 }} /> : <SaveIcon />}
				>
					Save Theme
				</Button>
				<Button variant='outlined' color='primary' onClick={handleResetDocsColors} className={classes.button}>
					Reset Theme
				</Button>
			</Grid>
		</Grid>
	);
}

ColorTool.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ColorTool);
