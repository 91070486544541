import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { signup } from '../firebase/auth';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function SignIn(props) {
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [alert, setAlert] = React.useState({ message: '', severity: '' });

	const classes = useStyles();

	console.log(props);

	const handleUsernameChange = (e) => {
		setUsername(e.target.value);
		if (e.key === 'Enter') {
			signup(username, password).catch(() => {
				setAlert({ message: 'Error creating your account.', severity: 'error' });
			});
		}
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
		if (e.key === 'Enter') {
			signup(username, password).catch(() => {
				setAlert({ message: 'Error creating your account.', severity: 'error' });
			});
		}
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Sign up
				</Typography>
				<TextField variant='outlined' margin='normal' required fullWidth label='Email' type='email' autoFocus onKeyUp={handleUsernameChange} />
				<TextField variant='outlined' margin='normal' required fullWidth label='Password' type='password' onKeyUp={handlePasswordChange} />
				<Grid container justify='flex-end'>
					<Grid item>
						<Link to='/login'>Login</Link>
					</Grid>
				</Grid>
				<Button
					fullWidth
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={() => {
						signup(username, password).catch(() => {
							setAlert({ message: 'Error creating your account.', severity: 'error' });
						});
					}}
				>
					Sign Up
				</Button>
			</div>
			<Snackbar
				open={alert.message !== ''}
				autoHideDuration={4000}
				onClose={() => {
					setAlert({ message: '', severity: '' });
				}}
			>
				<Alert
					onClose={() => {
						setAlert({ message: '', severity: '' });
					}}
					severity={alert.severity}
				>
					{alert.message}
				</Alert>
			</Snackbar>
		</Container>
	);
}
