import React, { Component } from 'react';
import axios from 'axios';

import { rgbToHex } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import { QRCode } from 'react-qrcode-logo';

class Render extends Component {
	constructor(props) {
		super(props);
		this.state = {
			person: [],
			associates: [],
		};
	}

	componentDidMount() {
		this.getPerson();
	}

	async getPerson() {
		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/get?query={"_id":"${this.props.match.params.id}"}`)
				.then((response) => {
					console.log(response.data.result.length);
					if (response.data.result.length === 1) {
						this.setState({ person: response.data?.result[0] }, () => {
							var tempAssociates = [];
							this.state.person.associates.forEach((associate) => {
								tempAssociates.push(associate);
							});
							this.setState({ associates: tempAssociates });
						});
					}
				})
				.catch((error) => {
					this.setState({ loading: false });
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	}

	render() {
		var today = new Date(),
			date = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

		return (
			<div style={{ background: '#fff' }}>
				<Grid container spacing={2}>
					<Grid item xs={12} style={{ paddingBottom: 0, marginLeft: -4, overflow: 'hidden' }}>
						<Typography id='callbackCheck' value={this.state.person.name} style={{ fontSize: '10vw', whiteSpace: 'nowrap', color: '#000' }} variant='h3'>
							{this.state.person.name}
						</Typography>
					</Grid>
					<Grid item xs={6} sm container style={{ paddingTop: 0 }}>
						<Grid item xs={12}>
							<Typography style={{ whiteSpace: 'nowrap', color: '#000' }} variant='h5'>
								{this.state.person.nonbusiness ? 'Nonbusiness' : this.state.person.company}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Avatar
								style={{
									padding: 0,
									width: '315px',
									height: '315px',
								}}
							>
								<img alt='person' src={this.state.person.picture ? this.state.person.picture : '/images/qrLogo.png'} />
							</Avatar>
						</Grid>
					</Grid>
					<div style={{ position: 'fixed', right: 28, bottom: 300, zIndex: 1001 }}>
						<img
							style={{ filter: 'brightness(0%)' }}
							alt={this.state.person.name}
							width={235}
							src={!this.props.data.logo ? '' : this.props.data.logo}
							onError={(e) => {
								e.target.src =
									'https://logo.pennair.tech/logo?tag=Front%20Desk%20Assistant&penn=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&air=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&line=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&text=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&dot=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4);
							}}
						/>
					</div>
					<div style={{ position: 'fixed', right: 18, bottom: 44, zIndex: 999 }}>
						<QRCode value={this.props.match.params.id} size={235} />
					</div>
					<div style={{ position: 'fixed', right: 24, bottom: 10, zIndex: 1000, color: '#000' }}>
						<div style={{ textAlign: 'right', fontSize: '32px' }}>{date}</div>
					</div>
					<Grid item xs={8}>
						{this.state.associates.map((associate, i) => (
							<Chip
								style={{ margin: '4px', color: '#000' }}
								avatar={<Avatar alt={associate.displayName} src={associate.photo ? associate.photo : '/no-image'} />}
								label={associate.displayName}
								variant='outlined'
							/>
						))}
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default Render;
