import React, { Component } from 'react';
import axios from 'axios';

import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import CircularProgress from '@material-ui/core/CircularProgress';

import Home from './components/home/Home';
import Signin from './components/signin/Signin';
import Signout from './components/signout/Signout';

// import Management from './components/management/Management';
import Render from './components/render/Render';

class Client extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};

		this.default = createMuiTheme({
			palette: {
				primary: {
					light: '#15c4ff',
					main: '#00a9e2',
					dark: '#0088b6',
					contrastText: '#fff',
				},
			},
		});

		this.renderTheme = createMuiTheme({
			palette: {
				primary: {
					light: '#15c4ff',
					main: '#00a9e2',
					dark: '#0088b6',
					contrastText: '#fff',
				},
			},
		});
	}

	componentDidMount() {
		this.getTheme();

		this.props.socket.emit('join', 'themeChange:' + window.location.pathname.split('/')[2]);

		this.props.socket.on('res:themeChange', () => {
			console.log('update theme');
			this.getTheme();
		});
	}

	getTheme = async () => {
		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/get?query={"uid":"${window.location.pathname.split('/')[2]}"}`)
				.then((response) => {
					console.log(response.data.result.length);
					if (response.data.result.length) {
						var theme = response.data?.result[0]?.theme;

						this.default = createMuiTheme({
							palette: {
								type: theme?.type,
								primary: {
									light: theme?.light,
									main: theme?.main,
									dark: theme?.dark,
									contrastText: theme?.contrastText,
								},
							},
						});
					}

					this.setState({ loading: false, data: theme });
				})
				.catch((error) => {
					this.setState({ loading: false });
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	};

	render() {
		return this.state.loading === true ? (
			<MuiThemeProvider theme={this.default}>
				<CssBaseline />
				<CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
			</MuiThemeProvider>
		) : (
			<MuiThemeProvider theme={this.default}>
				<CssBaseline />
				<>
					{this.state.data ? (
						<Switch>
							<Route exact path='/client/:site/' component={(props) => <Home {...props} data={this.state.data} />} />
							<Route exact path='/client/:site/signin' component={(props) => <Signin {...props} data={this.state.data} socket={this.props.socket} />} />
							<Route exact path='/client/:site/signout' component={(props) => <Signout {...props} data={this.state.data} />} />
							{/* <Route exact path='/client/:site/management' component={(props) => <Management {...props} data={this.state.data} />} /> */}

							<Route exact path='/client/:site/render/:id' component={(props) => <Render {...props} data={this.state.data} />} />
						</Switch>
					) : (
						<div>Not found</div>
					)}
				</>
			</MuiThemeProvider>
		);
	}
}

export default Client;
