import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class Steps extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: this.props.step,
			steps: this.props.steps,
		};
	}

	setStep(value) {
		this.setState({ step: value });
	}

	render() {
		return (
			<div style={{ position: 'fixed', left: 0, bottom: 0, width: '100%' }}>
				<Stepper activeStep={this.state.step} alternativeLabel>
					{this.state.steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}
}

export default Steps;
