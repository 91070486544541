import React, { Component } from 'react';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';

import AdSyncSettings from './AdSyncSettings';
// import EditUser from './EditUser';

class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			site: this.props.user.uid,
			vistingFilter: '',
			associates: [],
			loading: true,
		};
		props.setTitle('Users');
	}

	componentDidMount() {
		this.getAssociates();
	}

	getAssociates = async () => {
		// 	db.collection('locations')
		// 		.doc(this.state.site)
		// 		.get()
		// 		.then((doc) => {
		// 			if (doc.exists) {
		// 				if (doc.data().associates) {
		// 					var data = doc.data().associates.sort((a, b) => (a.name > b.name ? 1 : -1));
		// 					this.setState({ associates: data, loading: false });
		// 				} else {
		// 					this.setState({ loading: false });
		// 				}
		// 			} else {
		// 				this.setState({ loading: false });
		// 			}
		// 		});

		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/associates/get?query={"uid":"${this.state.site}"}`)
				.then((response) => {
					console.log(response?.data?.result[0]?.associates);

					var associates = response?.data?.result[0]?.associates.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
					this.setState({ associates: associates, loading: false });
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	};

	// saveAssociates = (associate) => {
	// 	var associatesTemp = this.state.associates;
	// 	associatesTemp[associate.index] = { name: associate.name, title: associate.title, email: associate.email, photo: associate.photo };
	// 	this.setState({ associates: associatesTemp });

	// 	db.collection('/locations/').doc(this.state.site).update({
	// 		associates: associatesTemp,
	// 	});
	// };

	render() {
		return this.state.loading === true ? (
			<div>
				<CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
			</div>
		) : (
			<div>
				<Grid container spacing={3}>
					<Grid item xs={12} style={{ marginBottom: 16 }}>
						<AdSyncSettings user={this.props.user} getAssociates={this.getAssociates} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							type='text'
							value={this.state.vistingFilter}
							onChange={(e) => {
								this.setState({ vistingFilter: e.target.value });
							}}
							onKeyDown={this._handleKeyDown}
							label='Search'
							variant='outlined'
							style={{ marginTop: -30, marginBottom: 16 }}
							fullWidth
						/>
						<Grid container spacing={3}>
							{this.state.associates?.map((associate, i) => (
								<Grid
									key={associate.email}
									item
									xl={3}
									lg={4}
									md={6}
									sm={12}
									style={{ display: this.state.associates[i].displayName.toLowerCase().includes(this.state.vistingFilter.toLowerCase()) ? 'block' : 'none' }}
								>
									<Card style={{ padding: 12 }}>
										<div style={{ height: '49px' }}>
											<ListItem style={{ padding: 0, position: 'relative', top: -4 }}>
												<ListItemAvatar>
													<Avatar alt={associate.displayName} src={associate.photo ? associate.photo : '/no-image'} />
												</ListItemAvatar>
												<ListItemText primary={associate.displayName} secondary={associate.title} />
												{/* <ListItemAvatar>
													<EditUser associate={associate} saveAssociates={this.saveAssociates} index={i} />
												</ListItemAvatar> */}
											</ListItem>
										</div>
									</Card>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default Signin;
