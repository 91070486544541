import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { rgbToHex } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';

import BackspaceIcon from '@material-ui/icons/Backspace';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import YoutubeBackground from 'react-youtube-background';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logoClickCount: 0,
			controlsDrawer: false,
		};
		this.SpeedDialChild = React.createRef();
	}

	render() {
		return (
			<div>
				<AppBar position='static'>
					<Toolbar>
						<Typography align='center' variant='h6' color='inherit'>
							Welcome to {this.props.data.companyName}
						</Typography>

						<img
							alt='logo'
							style={{ position: 'absolute', right: '20px', height: '48px' }}
							src={!this.props.data.logo ? '' : this.props.data.logo}
							onError={(e) => {
								e.target.src =
									'https://logo.pennair.tech/logo?tag=Front%20Desk%20Assistant&penn=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&air=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&line=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&text=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&dot=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4);
							}}
						/>
					</Toolbar>
				</AppBar>

				{this.props.data.backgroundType === 'video' ? (
					<YoutubeBackground
						style={{ position: 'fixed', zIndex: -999, width: '100%', height: '100%' }}
						videoId={this.props.data.backgroundValue}
						overlay={'rgba(0,0,0,0.7)'}
						playerOptions={{ controls: 0, loop: 1, modestbranding: 1 }}
					>
						<Container>
							<Link to={'/client/' + this.props.match.params.site + '/signin'}>
								<Fab
									style={{
										position: 'fixed',
										right: '12px',
										bottom: '12px',
									}}
									variant='extended'
									color='primary'
									size='large'
									aria-label='add'
								>
									Visitor Sign In
									<ExitToAppIcon style={{ marginLeft: '8px' }} />
								</Fab>
							</Link>
							<Link to={'/client/' + this.props.match.params.site + '/signout'}>
								<Fab
									style={{
										position: 'fixed',
										left: '12px',
										bottom: '12px',
									}}
									variant='extended'
									size='large'
									aria-label='add'
								>
									<BackspaceIcon style={{ marginRight: '8px' }} />
									Sign Out
								</Fab>
							</Link>
							{/* <SpeedDial ref={this.SpeedDialChild} site={this.props.match.params.site} /> */}
						</Container>
					</YoutubeBackground>
				) : (
					<div style={{ width: '100%', height: 'calc(100vh - 64px)', background: '#808080', overflow: 'hidden' }}>
						<img
							alt='BackgroundImage'
							src={this.props.data.backgroundValue}
							onError={(e) => {
								e.target.src = '/images/' + Math.floor(Math.random() * 10) + '.jpg';
							}}
							style={{ objectFit: 'cover', width: '100%', height: '100%' }}
						/>

						<Container>
							<Link to={'/client/' + this.props.match.params.site + '/signin'}>
								<Fab
									style={{
										position: 'fixed',
										right: '12px',
										bottom: '12px',
									}}
									variant='extended'
									color='primary'
									size='large'
									aria-label='add'
								>
									Visitor Sign In
									<ExitToAppIcon style={{ marginLeft: '8px' }} />
								</Fab>
							</Link>
							<Link to={'/client/' + this.props.match.params.site + '/signout'}>
								<Fab
									style={{
										position: 'fixed',
										left: '12px',
										bottom: '12px',
									}}
									variant='extended'
									size='large'
									aria-label='add'
								>
									<BackspaceIcon style={{ marginRight: '8px' }} />
									Sign Out
								</Fab>
							</Link>
							{/* <SpeedDial ref={this.SpeedDialChild} site={this.props.match.params.site} /> */}
						</Container>
					</div>
				)}
			</div>
		);
	}
}

export default Home;
