import React, { Component } from 'react';
import axios from 'axios';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ColorTool from './ColorTool';

import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../Alert';

class Printing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			site: this.props.user.uid,

			light: '#33bae7',
			main: '#00a9e2',
			dark: '#00769e',
			contrastText: '#000',
			type: 'light',

			backgroundType: '',
			backgroundValue: '',

			loading: true,
		};
		props.setTitle('Theme');
	}

	componentDidMount() {
		this.getThemeSettings();
	}

	getThemeSettings = async () => {
		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/get?query={"uid":"${this.props.user.uid}"}`)
				.then((response) => {
					console.log(response.data.result.length);
					if (response.data.result.length) {
						var theme = response.data?.result[0]?.theme;

						this.setState({
							light: theme?.light || '#33bae7',
							main: theme?.main || '#00a9e2',
							dark: theme?.dark || '#00769e',
							contrastText: theme?.contrastText || '#000',
							type: theme?.type || 'light',
							backgroundType: theme?.backgroundType || '',
							backgroundValue: theme?.backgroundValue || '',
							companyName: theme?.companyName,
							logo: theme?.logo,
							loading: false,
						});
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbarOpen: false,
			snackbarMessage: '',
		});
	};
	handleSnackbarOpen = (message, severity) => {
		this.setState({
			snackbarOpen: true,
			snackbarMessage: message,
			snackbarSeverity: severity,
		});
	};

	render() {
		return this.state.loading === true ? (
			<div>
				<CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
			</div>
		) : (
			<div>
				{/* {JSON.stringify(this.state)} */}
				<Paper elevation={9} style={{ paddingLeft: 12, paddingRight: 12 }}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<ColorTool {...this.props} savedTheme={this.state} user={this.props.user} socket={this.props.socket} />
						</Grid>
					</Grid>
				</Paper>
				<Snackbar open={this.state.snackbarOpen} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
					<Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
						{this.state.snackbarMessage}
					</Alert>
				</Snackbar>
			</div>
		);
	}
}

export default Printing;
