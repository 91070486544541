import React, { Component } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { rgbToHex } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Avatar from '@material-ui/core/Avatar';
import QrReader from 'react-qr-reader';
import welcomeSfx from '../../sounds/welcome.mp3';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class Signout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			result: null,
			scannerEnable: true,
			alert: null,
			isLoading: false,
		};
	}

	componentDidMount() {
		// this.getSignedinPeople();
	}

	handleScan = (data) => {
		console.log(data);

		if (data && this.state.scannerEnable) {
			this.setState({
				result: data,
				scannerEnable: false,
				isLoading: true,
			});
			const welcomeSfxAudio = new Audio(welcomeSfx);
			welcomeSfxAudio.play();
			this.handleSignOut(data);
		}
	};
	handleError = (err) => {
		console.error(err);
	};

	// handleSignOut(id) {
	// 	console.log(id);
	// 	var signedinPersonRef = db.collection('locations/' + this.props.match.params.site + '/signedin').doc(id);

	// 	signedinPersonRef
	// 		.get()
	// 		.then((doc) => {
	// 			if (doc.exists) {
	// 				// Set the "capital" field of the city 'DC'
	// 				if (doc.data().signedin) {
	// 					return signedinPersonRef
	// 						.update({
	// 							signedin: false,
	// 						})
	// 						.then(() => {
	// 							console.log('Document successfully updated!');
	// 							this.successAlert();
	// 						})
	// 						.catch((error) => {
	// 							// The document probably doesn't exist.
	// 							console.error('Error updating document: ', error);
	// 							this.errorAlert();
	// 						});
	// 				} else {
	// 					this.warningAlert();
	// 				}
	// 			} else {
	// 				// doc.data() will be undefined in this case
	// 				console.log('No such document!');
	// 				this.errorAlert();
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log('Error getting document:', error);
	// 		});
	// }

	handleSignOut = async (id) => {
		try {
			if (id.length === 24) {
				// get signins
				await axios
					.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/get?query={"_id":"${id}"}`)
					.then(async (response) => {
						if (response.data.result.length === 1) {
							var signin = response.data.result[0];

							console.log(response.data.result.length);

							console.log(signin);

							if (!signin.signedin) {
								this.warningAlert();
							} else {
								try {
									// set signin processed
									await axios
										.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/update?query={"_id":"${id}"}`, {
											body: {
												signedin: false,
											},
										})
										.then((response) => {
											console.log('Document successfully updated!');
											this.successAlert();
										})
										.catch((error) => {
											console.error('Error updating document: ', error);
											this.errorAlert();
										});
								} catch (error) {
									console.error('Error updating document: ', error);
									this.errorAlert();
								}
							}
						} else {
							this.errorAlert();
						}
					})
					.catch((error) => {
						this.errorAlert();
					});
			} else {
				this.errorAlert();
			}
		} catch (error) {
			console.error(error);
		}
	};

	handleRetry = () => {
		this.hideAlert();
		this.setState({
			scannerEnable: true,
			isLoading: false,
		});
	};

	successAlert = () => {
		this.setState({
			isLoading: false,
		});
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ backgroundColor: this.props.data.type === 'dark' ? '#424242' : '#fff' }}
					title={'Thanks for visiting ' + this.props.data.companyName}
					onCancel={() => this.hideAlert()}
					customButtons={
						<React.Fragment>
							<a href={'/client/' + this.props.match.params.site} style={{ textDecoration: 'none' }}>
								<Button variant='contained' size='large' color='primary'>
									continue
								</Button>
							</a>
						</React.Fragment>
					}
				>
					Please return the lanyard to the front desk.
				</SweetAlert>
			),
		});
		this.timeoutReturnHome();
	};

	warningAlert = () => {
		this.setState({
			isLoading: false,
		});
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ backgroundColor: this.props.data.type === 'dark' ? '#424242' : '#fff' }}
					title='You are already signed out'
					onCancel={() => this.hideAlert()}
					customButtons={
						<React.Fragment>
							<a href={'/client/' + this.props.match.params.site} style={{ textDecoration: 'none' }}>
								<Button variant='contained' size='large' color='primary'>
									continue
								</Button>
							</a>
						</React.Fragment>
					}
				>
					Please return the lanyard to the front desk.
				</SweetAlert>
			),
		});
		this.timeoutReturnHome();
	};

	errorAlert = () => {
		this.setState({
			isLoading: false,
		});
		this.setState({
			alert: (
				<SweetAlert
					danger
					style={{ backgroundColor: this.props.data.type === 'dark' ? '#424242' : '#fff' }}
					title='An error occured!'
					onCancel={() => this.hideAlert()}
					customButtons={
						<React.Fragment>
							<Button variant='contained' size='large' color='secondary' onClick={this.handleRetry}>
								Retry
							</Button>
						</React.Fragment>
					}
				>
					Try rescanning the QR code.
				</SweetAlert>
			),
		});
	};
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	timeoutReturnHome() {
		setTimeout(() => {
			this.props.history.push('/client/' + this.props.match.params.site);
		}, 5000);
	}

	render() {
		return (
			<div>
				<AppBar position='static'>
					<Toolbar>
						<a href={'/client/' + this.props.match.params.site}>
							<IconButton edge='start' color='inherit' aria-label='menu' style={{ color: 'white' }}>
								<ArrowBackIcon />
							</IconButton>
						</a>
						<Typography align='center' variant='h6' color='inherit'>
							Scan QR Code
						</Typography>
						<img
							alt='logo'
							style={{ position: 'absolute', right: '20px', height: '48px' }}
							src={!this.props.data.logo ? '' : this.props.data.logo}
							onError={(e) => {
								e.target.src =
									'https://logo.pennair.tech/logo?tag=Front%20Desk%20Assistant&penn=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&air=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&line=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&text=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4) +
									'&dot=' +
									rgbToHex(this.props.data.contrastText).substring(1, 4);
							}}
						/>
					</Toolbar>
				</AppBar>
				<Backdrop open={this.state.isLoading} style={{ zIndex: 9999 }}>
					<CircularProgress color='inherit' />
				</Backdrop>

				<Container style={{ marginTop: '50px' }}>
					<div
						style={{
							margin: 'auto',
							width: '350px',
							height: '350px',
							position: 'absolute',
							left: 0,
							right: 0,
						}}
					>
						<h1 style={{ 'text-align': "center", fontSize: 10, opacity: 0.45 }}>*Camera may take up to 10 seconds to load</h1>
						<Avatar
							style={{
								padding: 0,
								width: '350px',
								height: '350px',
							}}
						>
							{this.state.scannerEnable && <QrReader
								showViewFinder={false}
								delay={100}
								facingMode={{ exact: 'user' }}
								onError={this.handleError}
								onScan={this.handleScan}
								style={{ height: '350px', width: '350px' }
								}
							/>}
						</Avatar>
					</div>
					<Typography style={{ position: 'fixed', bottom: 10 }} variant='body1'>
						Scan the QR code on your name tag to sign out.
					</Typography>
					{this.state.alert}
				</Container>
			</div>
		);
	}
}

export default Signout;
