import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import CircularProgress from '@material-ui/core/CircularProgress';
import SyncIcon from '@material-ui/icons/Sync';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../Alert';

class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			site: props.user.uid,

			loading: true,
			syncloading: false,
			lastSync: '',

			enabled: true,
			tenant: '',
			group: '',
			client_id: '',
			client_secret: '',

			snackbarOpen: false,
			snackbarMessage: null,
			snackbarSeverity: null,
		};
	}

	componentDidMount() {
		this.getSettings();
	}

	async getSettings() {
		try {
			// get user theme
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/associates/get?query={"uid":"${this.props.user.uid}"}`)
				.then((response) => {
					var settings = response.data?.result[0];
					this.setState({
						// enabled: settings?.enabled || false,
						tenant: settings?.tenant || '',
						group: settings?.group || '',
						client_id: settings?.client_id || '',
						client_secret: settings?.client_secret || '',
						lastSync: settings?.lastSync || 'Never',
						loading: false,
					});
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} catch (error) {
			console.error(error);
		}
	}

	handleSyncSettingsChange = (prop) => (event) => {
		this.setState({ [prop]: event.target.value }); //set new value
	};

	// handleEnableSwitch = (event) => {
	// 	var checked = event.target.checked;
	// 	this.setState({ enabled: checked });

	// 	var adSyncEnabledRef = db.collection('/locations/' + this.state.site + '/preferences').doc('adsync');
	// 	adSyncEnabledRef
	// 		.update({
	// 			enabled: checked,
	// 		})
	// 		.catch(() => {
	// 			adSyncEnabledRef.set({
	// 				enabled: checked,
	// 			});
	// 		});
	// };

	handleSaveAndSync = async () => {
		this.setState({ syncloading: true });

		try {
			await axios
				.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/associates/update?query={"uid":"${this.props.user.uid}"}`, {
					body: {
						enabled: this.state.enabled,
						tenant: this.state.tenant,
						group: this.state.group,
						client_id: this.state.client_id,
						client_secret: this.state.client_secret,
					},
				})
				.then(async (response) => {
					await axios
						.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + '/api/syncUsers/' + this.props.user.uid + '/' + this.state.tenant + '/' + this.state.group, {
							body: { client_id: this.state.client_id, client_secret: this.state.client_secret },
						})
						.then((response) => {
							this.props.getAssociates();
							this.setState({ syncloading: false });
							this.handleSnackbarOpen(response.data.result, 'success');
						})
						.catch((error) => {
							this.setState({ syncloading: false });
							var message = '';
							try {
								switch (error.response.status) {
									case 400:
										if (error.response.statusText === 'Bad Request') {
											message = 'Invalid Azure group!';
										} else {
											message = 'Invalid Azure credentials!';
										}
										break;
									case 401:
										message = 'Invalid client Secret!';
										break;
									case 403:
										message = 'Invalid API Permissions! Add admin consent for Directory.Read.All & User.Read.All';
										break;
									case 404:
										error.response.status = 400;
										message = 'Invalid Azure credentials!';
										break;
									case 500:
										message = 'Server error!';
										break;
									default:
										message = error.response.data.message;
								}

								this.handleSnackbarOpen(error.response.status + ' Error: ' + message, 'error');
							} catch (error) {
								this.handleSnackbarOpen('500 Error: Server error try again later.', 'error');
							}
						});
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
					this.handleSnackbarOpen('Error: Updating Azure AD Sync', 'error');
				});
		} catch (error) {
			console.error(error);
		}

		// db.collection('/locations/' + this.state.site + '/preferences')
		// 	.doc('adsync')
		// 	.update({
		// 		enabled: this.state.enabled,
		// 		tenant: this.state.tenant,
		// 		group: this.state.group,
		// 		client_id: this.state.client_id,
		// 		client_secret: this.state.client_secret,
		// 	})
		// 	.then(async () => {

		// 	})
		// 	.catch((error) => {
		// 		// The document probably doesn't exist.
		// 		console.error('Error updating document: ', error);
		// 		this.handleSnackbarOpen('Error: Updating Azure AD Sync', 'error');
		// 	});
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbarOpen: false,
			snackbarMessage: '',
		});
	};
	handleSnackbarOpen = (message, severity) => {
		this.setState({
			snackbarOpen: true,
			snackbarMessage: message,
			snackbarSeverity: severity,
		});
	};

	render() {
		return (
			<Card style={{ padding: 16 }}>
				{this.state.loading === true ? (
					<CircularProgress />
				) : (
					<Grid container spacing={3}>
						{/* <Grid item xs={12}>
							<FormControlLabel control={<Switch checked={this.state.enabled} onChange={this.handleEnableSwitch} name='checkedB' color='primary' />} label='Azure AD Sync' />
						</Grid> */}
						<Grid item md={6} sm={12}>
							<FormControl variant='outlined' disabled={!this.state.enabled} fullWidth>
								<InputLabel>Azure Tenant ID</InputLabel>
								<OutlinedInput
									value={this.state.tenant}
									onChange={this.handleSyncSettingsChange('tenant')}
									endAdornment={
										<InputAdornment position='end'>
											<a
												href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in'
												target='_blank'
												rel='noopener noreferrer'
											>
												<IconButton aria-label='Go to help page' edge='end' disabled={!this.state.enabled}>
													<HelpOutlineIcon />
												</IconButton>
											</a>
										</InputAdornment>
									}
									labelWidth={115}
								/>
							</FormControl>
						</Grid>
						<Grid item md={6} sm={12}>
							<FormControl variant='outlined' disabled={!this.state.enabled} fullWidth>
								<InputLabel>Azure Group ID</InputLabel>
								<OutlinedInput
									value={this.state.group}
									onChange={this.handleSyncSettingsChange('group')}
									endAdornment={
										<InputAdornment position='end'>
											<a href='https://docs.microsoft.com/en-us/onedrive/find-your-office-365-tenant-id' target='_blank' rel='noopener noreferrer'>
												<IconButton aria-label='Go to help page' edge='end' disabled={!this.state.enabled}>
													<HelpOutlineIcon />
												</IconButton>
											</a>
										</InputAdornment>
									}
									labelWidth={115}
								/>
							</FormControl>
						</Grid>
						<Grid item md={6} sm={12}>
							<FormControl variant='outlined' disabled={!this.state.enabled} fullWidth>
								<InputLabel>Application (client) ID</InputLabel>
								<OutlinedInput
									value={this.state.client_id}
									onChange={this.handleSyncSettingsChange('client_id')}
									endAdornment={
										<InputAdornment position='end'>
											<a
												href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in'
												target='_blank'
												rel='noopener noreferrer'
											>
												<IconButton aria-label='Go to help page' edge='end' disabled={!this.state.enabled}>
													<HelpOutlineIcon />
												</IconButton>
											</a>
										</InputAdornment>
									}
									labelWidth={115}
								/>
							</FormControl>
						</Grid>
						<Grid item md={6} sm={12}>
							<FormControl variant='outlined' disabled={!this.state.enabled} fullWidth>
								<InputLabel>Client Secret</InputLabel>
								<OutlinedInput
									value={this.state.client_secret}
									onChange={this.handleSyncSettingsChange('client_secret')}
									type='password'
									endAdornment={
										<InputAdornment position='end'>
											<a
												href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#option-2-create-a-new-application-secret'
												target='_blank'
												rel='noopener noreferrer'
											>
												<IconButton aria-label='Go to help page' edge='end' disabled={!this.state.enabled}>
													<HelpOutlineIcon />
												</IconButton>
											</a>
										</InputAdornment>
									}
									labelWidth={115}
								/>
							</FormControl>
						</Grid>
						<Grid item xm={12}>
							<Tooltip title={'Last AD Sync: ' + (parseInt(this.state.lastSync.seconds) ? moment.unix(this.state.lastSync.seconds).format('h:mm A  MM/DD/YYYY') : this.state.lastSync)}>
								<Button
									variant='contained'
									color='primary'
									disabled={!this.state.enabled || this.state.syncloading}
									onClick={this.handleSaveAndSync}
									endIcon={this.state.syncloading ? <CircularProgress style={{ color: 'white', height: 20, width: 20 }} /> : <SyncIcon />}
								>
									Save & Sync
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				)}
				<Snackbar open={this.state.snackbarOpen} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
					<Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
						{this.state.snackbarMessage}
					</Alert>
				</Snackbar>
			</Card>
		);
	}
}

export default Signin;
