import React, { Component } from 'react';
import axios from 'axios';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../Alert';

class Printing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			site: this.props.user.uid,
			vistingFilter: '',

			openPrinterList: false,
			isLoadingPrinterList: false,
			printers: [],
			printerSelected: null,

			paperSizeVisable: false,
			openPaperSizeList: false,
			isLoadingPaperSizeList: false,
			paperSizeSelected: null,

			landscape: false,

			marginTop: 0,
			marginLeft: 0,
			marginRight: 0,
			marginBottom: 0,

			loading: true,
		};
		props.setTitle('Printing');
	}

	componentDidMount() {
		this.getPrintSettings();

		//
		// ping
		this.handleSnackbarOpen('Checking client status...', 'info');
		var timeoutCheck = setTimeout(() => {
			this.setState(
				{
					snackbarOpen: false,
					snackbarMessage: '',
				},
				() => {
					this.handleSnackbarOpen('Client is offline.', 'warning');
				}
			);
		}, 5000);

		this.props.socket.emit('join', 'pong:' + this.props.user.uid);
		this.props.socket.emit('req:ping', this.props.user.uid);

		this.props.socket.once('res:pong', (site) => {
			if (site === this.props.user.uid) {
				this.handleSnackbarOpen('Client is online.', 'success');
				clearTimeout(timeoutCheck);
			}
		});
		// pong
		//
	}

	getPrintSettings = async () => {
		try {
			// get print settings
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/get?query={"uid":"${this.props.user.uid}"}`)
				.then((response) => {
					if (response.data.result.length) {
						var printing = response.data?.result[0]?.printing;

						console.log(printing);
						//console.log([
						//	{
						//		name: printing.printer,
						//		paperSizes: [
						//			{
						//				PaperName: printing.paperSize,
						//			},
						//		],
						//	},
						//]);

						this.setState(
							{
								printers: [
									{
										name: printing.printer,
										paperSizes: [printing.paperSize],
									},
								],
								// paperSizes: [],

								printerSelected: printing.printer,
								paperSizeSelected: printing.paperSize,
								marginTop: printing.marginTop,
								marginLeft: printing.marginLeft,
								marginRight: printing.marginRight,
								marginBottom: printing.marginBottom,
								landscape: printing.landscape,
								loading: false,
							},
							() => {
								console.log(this.state.printers);
							}
						);
					} else {
						this.setState({ loading: false });
					}
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	savePrintSettings = async () => {
		try {
			// save print settings
			await axios
				.post((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/update?query={"uid":"${this.props.user.uid}"}`, {
					body: {
						printing: {
							printer: this.state.printerSelected,
							paperSize: this.state.paperSizeSelected,
							marginTop: this.state.marginTop,
							marginLeft: this.state.marginLeft,
							marginRight: this.state.marginRight,
							marginBottom: this.state.marginBottom,
							landscape: this.state.landscape,
						},
					},
				})
				.then((response) => {
					// console.log(response.data);
					console.log('Document successfully written!');
					this.setState({ saveloading: false });

					this.props.socket.emit('req:relayThemeChange', this.props.user.uid);
				})
				.catch((error) => {
					console.error('Error writing document: ', error);
					this.setState({ saveloading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	testPrint = () => {
		//
		// test print
		this.handleSnackbarOpen('Requesting test print....', 'info');
		var timeoutCheck = setTimeout(() => {
			this.setState(
				{
					snackbarOpen: false,
					snackbarMessage: '',
				},
				() => {
					this.handleSnackbarOpen('Error sending print job!', 'error');
				}
			);
		}, 5000);

		this.props.socket.emit('join', 'testPrintQueued:' + this.props.user.uid);
		this.props.socket.emit('req:relayTestPrint', this.props.user.uid);

		this.props.socket.once('res:testPrintQueued', (site) => {
			if (site === this.props.user.uid) {
				this.handleSnackbarOpen('Test print job queued.', 'success');
				clearTimeout(timeoutCheck);
			}
		});
	};

	printerSelected = (event, value) => {
		console.log(value);
		this.setState({ printerSelected: value, openPaperSizeList: false, isLoadingPaperSizeList: false, paperSizeSelected: null, paperSizeVisable: false });
	};
	paperSizeSelected = (event, value) => {
		this.setState({ paperSizeSelected: value });
	};
	handleLandscapeChange = (event, value) => {
		this.setState({ landscape: value });
	};
	handleMarginChange = (prop) => (event) => {
		var value = 0;
		const re = /^[0-9\b]+$/;
		if (re.test(event.target.value)) {
			value = parseInt(event.target.value);
		} else {
			value = 0;
		}

		this.setState({ [prop]: parseInt(value) });
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbarOpen: false,
			snackbarMessage: '',
		});
	};
	handleSnackbarOpen = (message, severity) => {
		this.setState({
			snackbarOpen: true,
			snackbarMessage: message,
			snackbarSeverity: severity,
		});
	};

	render() {
		return this.state.loading === true ? (
			<div>
				<CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
			</div>
		) : (
			<div>
				<Paper elevation={9} style={{ paddingLeft: 12, paddingRight: 12 }}>
					<Grid container spacing={3}>
						<Grid item md={6} sm={12} xs={12}>
							<div>
								<TextField value={this.state.printerSelected} label='Printer Name(Must be exact)' variant='outlined' fullWidth type='text' onChange={(e) => { this.setState({ printerSelected: e.target.value }) }} />
								{/*
								<Autocomplete
									value={this.state.printerSelected}
									id='printer-list'
									open={this.state.openPrinterList}
									onOpen={() => {
										this.setState({ openPrinterList: true, isLoadingPrinterList: true });

										this.props.socket.emit('join', 'relayGetPrinterList:' + this.props.user.uid);
										this.props.socket.emit('req:relayGetPrinterList', this.props.user.uid);

										var timeoutCheck = setTimeout(() => {
											this.setState({ openPrinterList: false, isLoadingPrinterList: false }, () => {
												this.props.socket.off('res:relayGetPrinterList');
												this.handleSnackbarOpen('Client is offline.', 'error');
											});
										}, 15000);

										this.props.socket.once('res:relayGetPrinterList', (printers) => {
											clearTimeout(timeoutCheck);

											console.log("Successfully got printers");
											console.log(printers);

											if (typeof printers === 'string') {
												this.setState({ isLoadingPrinterList: false });
												this.handleSnackbarOpen(printers, 'error');
											} else {
												this.setState({ printers: printers, isLoadingPrinterList: false });
											}
										});
									}}
									onClose={() => this.setState({ openPrinterList: false })}
									onChange={this.printerSelected}
									getOptionSelected={(option, value) => option.name === value.name}
									options={this.state?.printers?.map((option) => option.name)}
									//options={this.state?.printers?.map((option) => option.printerName)}
									loading={this.state.isLoadingPrinterList}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Printer'
											variant='outlined'
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.state.isLoadingPrinterList ? <CircularProgress color='inherit' size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
										*/}</div>
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							<div>
								<TextField value={this.state.paperSizeSelected} label='Printer Size(Example: 2.4 x 3.9)' variant='outlined' fullWidth type='text' onChange={(e) => { this.setState({ paperSizeSelected: e.target.value }) }} />
								{/*
								<Autocomplete
									disabled={!this.state.printerSelected}
									value={this.state.paperSizeSelected}
									id='papersize-list'
									open={this.state.openPaperSizeList}
									onOpen={() => {
										this.setState({ openPaperSizeList: true });
										this.setState({ isLoadingPaperSizeList: true });
										this.props.socket.emit('join', 'relayGetPrinterList:' + this.props.user.uid);
										this.props.socket.emit('req:relayGetPrinterList', this.props.user.uid);

										this.props.socket.once('res:relayGetPrinterList', (printers) => {
											if (typeof printers === 'string') {
												this.handleSnackbarOpen(printers, 'error');
											} else {
												this.setState({ printers: printers });
												this.setState({ isLoadingPaperSizeList: false });
											}
										});
									}}
									onClose={() => this.setState({ openPaperSizeList: false })}
									onChange={this.paperSizeSelected}
									getOptionSelected={(option, value) => option.PaperName === value.PaperName}
									options={this.state?.printers?.filter((p) => p.name === this.state.printerSelected)[0]?.paperSizes.map((option) => option)}
									loading={this.state.isLoadingPaperSizeList}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Paper Size'
											variant='outlined'
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{this.state.isLoadingPaperSizeList ? <CircularProgress color='inherit' size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
								*/}</div>
						</Grid>

						<Grid item xs={12}>
							<Typography variant='h6' gutterBottom>
								Margins:
							</Typography>
							<TextField value={this.state.marginTop} label='Top' variant='outlined' fullWidth type='number' onChange={this.handleMarginChange('marginTop')} />
						</Grid>
						<Grid item xs={6}>
							<TextField value={this.state.marginLeft} label='Left' variant='outlined' fullWidth type='number' onChange={this.handleMarginChange('marginLeft')} />
						</Grid>
						<Grid item xs={6}>
							<TextField value={this.state.marginRight} label='Right' variant='outlined' fullWidth type='number' onChange={this.handleMarginChange('marginRight')} />
						</Grid>
						<Grid item xs={12}>
							<TextField value={this.state.marginBottom} label='Bottom' variant='outlined' fullWidth type='number' onChange={this.handleMarginChange('marginBottom')} />
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel control={<Checkbox checked={this.state.landscape} onChange={this.handleLandscapeChange} color='primary' />} label='Landscape' />
						</Grid>
						<Grid item xs={12}>
							<Button variant='contained' color='primary' disabled={!(this.state.printerSelected && this.state.paperSizeSelected)} onClick={this.savePrintSettings}>
								Save
							</Button>
							<Button variant='contained' disabled={!(this.state.printerSelected && this.state.paperSizeSelected)} onClick={this.testPrint} style={{ float: 'right' }}>
								Test Print
							</Button>
						</Grid>
					</Grid>
				</Paper>
				<Snackbar open={this.state.snackbarOpen} autoHideDuration={5000} onClose={this.handleSnackbarClose}>
					<Alert onClose={this.handleSnackbarClose} severity={this.state.snackbarSeverity}>
						{this.state.snackbarMessage}
					</Alert>
				</Snackbar>
			</div>
		);
	}
}

export default Printing;
