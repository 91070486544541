import React from 'react';

import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import DashboardIcon from '@material-ui/icons/Dashboard';
import HistoryIcon from '@material-ui/icons/History';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PrintIcon from '@material-ui/icons/Print';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BrushIcon from '@material-ui/icons/Brush';
import GetAppIcon from '@material-ui/icons/GetApp';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { logout, changePassword } from '../../firebase/auth';

class navMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			profileImg: '/logo.png',

			passwordDialogOpen: false,
			currentPassword: '',
			password1: '',
			password2: '',

			downloadMsg: 'Download Client',
			downloadClicked: false,
		};
	}

	handlePasswordDialogOpen = () => {
		this.setState({ passwordDialogOpen: true });
	};
	passwordChange = (e) => {
		var submit = false;
		if (e.key === 'Enter') {
			submit = true;
		}
		this.setState({ [e.target.id]: e.target.value }, () => {
			if (submit && this.state.password1 === this.state.password2 && this.state.password1.length >= 8) {
				this.SubmitPassword();
			}
		});
	};
	SubmitPassword = () => {
		changePassword(this.props.user.uid, this.state.currentPassword, this.state.password1).then(() => {
			this.setState({ passwordDialogOpen: false });
		});
	};

	handleDownloadClicked = () => {
		this.setState({ downloadClicked: true, downloadMsg: 'Downloading...' });
		window.location.href = '/api/download/' + this.props.user.uid;
	};

	render() {
		return (
			<div>
				<div>
					<ListItem>
						<ListItemAvatar>
							<Avatar alt='Profile' src={this.props.user.photoURL} />
						</ListItemAvatar>
						<ListItemText primary={this.props.user.displayName} secondary={this.props.user.email} />
					</ListItem>
					<Divider />
					<ListItem component={Link} to='/manage' button>
						<ListItemIcon>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary='Signed In' />
					</ListItem>
					<ListItem component={Link} to='/manage/history' button>
						<ListItemIcon>
							<HistoryIcon />
						</ListItemIcon>
						<ListItemText primary='History' />
					</ListItem>
					<ListItem component={Link} to='/manage/users' button>
						<ListItemIcon>
							<SupervisorAccountIcon />
						</ListItemIcon>
						<ListItemText primary='Users' />
					</ListItem>
					<ListItem component={Link} to='/manage/printing' button>
						<ListItemIcon>
							<PrintIcon />
						</ListItemIcon>
						<ListItemText primary='Printing' />
					</ListItem>
					<ListItem component={Link} to='/manage/theme' button>
						<ListItemIcon>
							<BrushIcon />
						</ListItemIcon>
						<ListItemText primary='Theme' />
					</ListItem>

					<ListSubheader inset>Account</ListSubheader>
					<ListItem onClick={this.handlePasswordDialogOpen} button>
						<ListItemIcon>
							<LockOutlinedIcon />
						</ListItemIcon>
						<ListItemText primary='Change Password' />
					</ListItem>
					<ListItem button onClick={logout}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary='Sign Out' />
					</ListItem>

					<ListSubheader inset>Client</ListSubheader>
					<ListItem onClick={this.handleDownloadClicked} button disabled={this.state.downloadClicked}>
						<ListItemIcon>
							<GetAppIcon />
						</ListItemIcon>
						<ListItemText primary={this.state.downloadMsg} />
					</ListItem>
				</div>

				{/* change password dialog */}
				<Dialog
					open={this.state.passwordDialogOpen}
					onClose={() => {
						this.setState({ passwordDialogOpen: false });
					}}
					aria-labelledby='password-dialog'
				>
					<DialogTitle id='form-dialog-title'>Change Password</DialogTitle>
					<DialogContent>
						<TextField autoFocus label='Current' type='password' id='currentPassword' fullWidth onKeyUp={(e) => this.passwordChange(e)} />
						<TextField label='New Password' type='password' id='password1' fullWidth onKeyUp={(e) => this.passwordChange(e)} />
						<TextField label='Confirm Password' type='password' id='password2' fullWidth onKeyUp={(e) => this.passwordChange(e)} />
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								this.setState({ passwordDialogOpen: false });
							}}
						>
							Cancel
						</Button>
						<Button onClick={this.SubmitPassword} color='primary' disabled={!(this.state.password1 === this.state.password2 && this.state.password1.length >= 8)}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
export default navMenu;
