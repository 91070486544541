import React, { Component } from 'react';
import axios from 'axios';

import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

class Management extends Component {
	constructor(props) {
		super(props);
		this.state = {
			signedinPeople: [],
			alert: null,
			lastSnapshot: null,
			moreDisabled: false,
		};
		props.setTitle('History');
	}

	componentDidMount() {
		this.getSignedinPeople();
	}

	getSignedinPeople = async () => {
		try {
			// get signins
			await axios
				.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/signins/get?query={"siteid":"${this.props.user.uid}"}&orderby={"timestamp":-1}`)
				.then((response) => {
					this.setState({ signedinPeople: response.data.result });
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} catch (error) {
			console.error(error);
		}
	};

	// getMoreSignedinPeople = async () => {
	// 	const snapshot = await db
	// 		.collection('locations/' + this.props.user.uid + '/signedin')
	// 		.limit(20)
	// 		.orderBy('timestamp', 'desc')
	// 		.startAfter(this.state.lastSnapshot)
	// 		.get();

	// 	if (snapshot.empty) {
	// 		console.log('No matching documents.');
	// 		this.setState({ moreDisabled: true });
	// 		return;
	// 	}

	// 	var data = this.state.signedinPeople;
	// 	var count = 0;
	// 	var lastDoc = null;
	// 	snapshot.forEach((doc) => {
	// 		// doc.data() is never undefined for query doc snapshot
	// 		data.push(doc.data());
	// 		data[count].id = doc.id;
	// 		count++;
	// 		lastDoc = doc;
	// 	});
	// 	this.setState({ signedinPeople: data, lastSnapshot: lastDoc });
	// };
	render() {
		return (
			<Grid container spacing={3}>
				{this.state.signedinPeople.map((person, i) => (
					<Grid item xs={12}>
						<Paper elevation={3}>
							<List>
								<ListItem alignItems='flex-start'>
									<ListItemAvatar>
										<Avatar
											style={{
												marginRight: '30px',
												width: '100px',
												height: '100px',
											}}
											alt={person.name}
											src={person.picture}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={
											<React.Fragment>
												<Typography component='span' variant='body1' color='textPrimary'>
													{person.name}
												</Typography>
												{' — '}
												{person.nonbusiness ? 'Nonbusiness' : person.company}
												<img
													alt='sign'
													height={50}
													style={{
														marginBottom: '-32px',
														marginLeft: '12px',
													}}
													src={person.signature}
												/>
											</React.Fragment>
										}
										secondary={
											<React.Fragment>
												<br />
												<Typography component='span' variant='body2' color='textPrimary'>
													Date: {moment(person.timestamp).format('lll')}
												</Typography>
												<br />
												<Typography component='span' variant='body2' color='textPrimary'>
													Visiting:
													<br />
												</Typography>
												{person.associates.map((associate, i) => (
													<Chip
														style={{ marginRight: '8px' }}
														avatar={<Avatar src={associate.photo} alt={associate.displayName.charAt(0).toUpperCase()} />}
														label={associate.displayName}
														color='primary'
														variant='outlined'
													/>
												))}
											</React.Fragment>
										}
									/>
								</ListItem>
							</List>
						</Paper>
					</Grid>
				))}
				{this.state.signedinPeople.length === 0 && (
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Typography variant='h5' gutterBottom style={{ marginTop: 100 }}>
							No one is signed in.
						</Typography>
					</Grid>
				)}
				{this.state.alert}
				{/* <Button variant='contained' color='primary' onClick={() => this.getMoreSignedinPeople()} disabled={this.state.moreDisabled}>
					Load more
				</Button> */}
			</Grid>
		);
	}
}

export default Management;
