import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';

import socketIOClient from 'socket.io-client';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import CircularProgress from '@material-ui/core/CircularProgress';

import Login from './components/Login';
import Signup from './components/Signup';
import Landing from './components/Landing';
import Manage from './components/manage/Manage';
import Client from './components/client/Client';

import { firebaseAuth } from './firebase/constants';

function PrivateRoute({ component: Component, authed, ...rest }) {
	return <Route {...rest} render={(props) => (authed ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)} />;
}

function PublicRoute({ component: Component, authed, ...rest }) {
	return <Route {...rest} render={(props) => (authed ? <Redirect to='/manage' /> : <Component {...props} />)} />;
}

var socket; // define socket
var reconnectAttempt = 0;

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authed: false,
			loading: true,
			user: null,
		};

		socket = new socketIOClient.connect(window.location.hostname.includes('localhost') ? '//localhost:8080' : '//' + window.location.hostname + ':' + window.location.port, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			reconnectionAttempts: 'Infinity',
			timeout: 5000,
		});

		socket.on('connect', () => {
			console.log('Connected to socket server!');
			try {
				document.querySelector('#electron-error').remove();
			} catch (error) { }
			reconnectAttempt = 0;
		});
		socket.on('disconnect', () => {
			console.log('disconnect');
		});
		socket.on('connect_error', (error) => {
			console.log('connect_error:' + error);
			if (reconnectAttempt === 0) {
				try {
					document
						.querySelector('body')
						.insertAdjacentHTML(
							'afterend',
							'<div id="electron-error" style="position:fixed;width:100%;height:100%;top: 0;opacity:0.8;z-index:9999;background:#000;"><div style="width: 250px;height: 70px;background-color: #fff;position: absolute;top: 50%;left: 50%;padding:12px;margin: -35px 0 0 -150px;">Disconnected from the server!</br>Attempting to reconnect...</div></div>'
						);
				} catch (e) { }
			}

			reconnectAttempt++;
		});

		this.default = createMuiTheme({
			palette: {
				primary: {
					light: '#15c4ff',
					main: '#00a9e2',
					dark: '#0088b6',
					contrastText: '#fff',
				},
			},
		});

		this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				this.setState(
					{
						user: user,
						authed: true,
					},
					async () => {
						try {
							// get user theme
							await axios
								.get((window.location.hostname === 'localhost' ? 'http://localhost:8080' : '') + `/api/mod/preferences/get?query={"uid":"${user.uid}"}`)
								.then((response) => {
									console.log(response.data.result.length);
									if (response.data.result.length) {
										var theme = response.data?.result[0]?.theme;

										this.default = createMuiTheme({
											palette: {
												type: theme?.type,
												primary: {
													light: theme?.light,
													main: theme?.main,
													dark: theme?.dark,
													contrastText: theme?.contrastText,
												},
											},
										});
									}

									this.setState({ loading: false });
								})
								.catch((error) => {
									this.setState({ loading: false, error: error });
									console.error(error);
								});
						} catch (error) {
							console.error(error);
						}
					}
				);
			} else {
				console.log(user);
				this.setState({
					user: null,
					authed: false,
					loading: false,
				});
			}
		});
	}

	render() {
		return this.state.loading === true ? (
			<MuiThemeProvider theme={this.default}>
				<CssBaseline />
				<CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
			</MuiThemeProvider>
		) : (
			<MuiThemeProvider theme={this.default}>
				<CssBaseline />
				{this.state.error ? (
					<h1>{this.state.error?.message || 'Unknown error'}</h1>
				) : (
					<BrowserRouter>
						<Switch>
							<PublicRoute authed={this.state.authed} exact path='/' component={(props) => <Landing {...props} socket={socket} />} />
							<PublicRoute authed={this.state.authed} exact path='/login' component={(props) => <Login {...props} socket={socket} />} />
							<PublicRoute authed={this.state.authed} exact path='/signup' component={(props) => <Signup {...props} socket={socket} />} />
							<PrivateRoute authed={true} path='/client' component={(props) => <Client {...props} socket={socket} />} />
							<PrivateRoute authed={this.state.authed} path='/manage' component={(props) => <Manage {...props} user={this.state.user} socket={socket} />} />
						</Switch>
					</BrowserRouter>
				)}
			</MuiThemeProvider>
		);
	}
}
