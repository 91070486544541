import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Types() {
	return (
		<div>
			<Typography variant="body1" gutterBottom>
				<b>Welcome To PennAir!</b>
				<br />
				<br />
				Visitors are welcome to visit during hours of operations. For your safety & security we have the following guidelines:
				<br />
				<br />
				(a) Smoking/tobacco use is prohibited in our facility. Please use designated outside area, which is in the parking lot to the left of the building. A cigarette butt
				receptacle is provided for your use.
				<br />
				(b) Agree to follow the divisions rules before entry is permitted into the building.
				<br />
				(c) All visitors must sign in and out through the main entrance lobby. In the event of an emergency, please report to the main entrance sign on the north side of
				the building.
				<br />
				(d) All visitors are required to read and acknowledge the Non-Disclosure and Waiver Agreement.
				<br />
				(e) Firearms/weapons are prohibited in our facility.
				<br />
				<br />
				Visitors Non-Disclosure and Waiver Agreement
				<br />
				<br />
				During my visit to your facility, I will learn and/or have disclosed to me proprietary or confidential information (including, without limitations, information
				relating to technology, trade secrets, processes, materials, equipment, drawings, specifications, prototypes and products) and may receive samples of products which
				not generally known to the public (hereinafter collectively called “Confidential Information”).
				<br />
				<br />
				In consideration of your permission to visit your facility & for the courtesies extended to me during my visit:
				<br />
				<br />
				1. I agree that I will not, without your written permission or that of your authorized representative, either;
				<br />
				<br />
				(a) Disclose or otherwise make available to others any Confidential Information disclosed to me during this and any subsequent visit which (i) was not known to me
				or my organization prior to disclosure by you, or (ii) is not now or subsequently becomes a part of the public domain as a result of publication or otherwise;
				<br />
				or
				<br />
				(b) Use or assist others in using or further developing in any manner any confidential information. (c) Use cameras or video technology to disclose confidential
				information.
				<br />
				<br />
				2. I also agree to conform to any applicable safety requirements, which are brought to my attention by any employee or by signs posted in the areas that I visit
				while on the premises, and to observe other reasonable safety precautions.
				<br />
				<br />
				Thank you
				<br />
			</Typography>
		</div>
	);
}
